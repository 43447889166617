import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VmGoods from '../views/VmGoods.vue'
import VmNet from '../views/VmNet.vue'
import VmInfo from '../views/VmInfo.vue'
import Test from '../views/Test.vue'
import Hello from '../views/Hello.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/vm/:id',
    name: 'Goods',
    component: VmGoods,
    props: true
  },
  {
    path: '/net/:id',
    name: 'Net',
    component: VmNet,
    props: true
  },
  {
    path: '/hi/:id',
    name: 'Hello',
    component: Hello,
    props: true
  },
  {
    path: '/nfo/:id',
    name: 'Machine',
    component: VmInfo,
    props: true
  },
  {
    path: '/test/:id',
    name: 'Test',
    component: Test,
    props: true
  }];
  

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
