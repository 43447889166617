var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"machine"},[_c('v-card',{staticClass:"d-flex align-start py-2",attrs:{"color":"grey lighten-3","flat":"","tile":""}},[_c('v-row',{staticClass:"text-body-2",attrs:{"justify":"space-around"}},[_c('v-col',[_c('strong',[_vm._v("#"+_vm._s(_vm.vminfo.Alias))]),_vm._v(" "+_vm._s(_vm.vminfo.ShortAddress)+" ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm.vminfo.Location)+" ")]),_c('v-col',[_c('v-icon',{attrs:{"small":"","color":"brown"}},[_vm._v("mdi-message-minus")]),_vm._v("Обновлено "+_vm._s(_vm.getSmartDate(_vm.updated))+" ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.goods,"items-per-page":"80","hide-default-footer":"true","disable-pagination":"true","loading":"true","loading-text":"Загрузка данных...","dense":""},scopedSlots:_vm._u([{key:"item.Channel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.makeCurItemShelf(item.Channel, item.Good))+" "),_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.getCurShelfPic(item.Block)))])]}},{key:"item.GoodName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.GoodName)+" ")]}},{key:"item.AmRmdr",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getRmdrColor(item.AmRmdr),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.AmRmdr)+" ")])]}},{key:"item.AmWare",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getRmdrColor(item.AmWare),"text-color":"black","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.AmWare)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }