var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"test"},[_c('v-card',{staticClass:"d-flex align-start py-2",attrs:{"color":"grey lighten-3","flat":"","tile":""}},[_c('v-row',{staticClass:"text-body-2",attrs:{"justify":"space-around"}},[_c('v-col',[_c('strong',[_vm._v("#Тест"+_vm._s(_vm.netinfo))]),_vm._v(" "+_vm._s(_vm.netinfo.ShortAddress)+" ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm.netinfo)+" ")]),_c('v-col',[_c('v-icon',{attrs:{"small":"","color":"brown"}},[_vm._v("mdi-message-minus")]),_vm._v("Обновлено "+_vm._s(_vm.getSmartDate(_vm.updated))+" ")],1)],1)],1),_c('v-card',{staticClass:"d-flex align-start",attrs:{"flat":"","tile":""}},[_c('v-toolbar',{attrs:{"dense":""}},[_c('v-btn',{attrs:{"icon":"","outlined":_vm.showselectors},on:{"click":function($event){return _vm.changeSelMode()}}},[_c('v-icon',{attrs:{"color":_vm.getSelColor()}},[_vm._v("mdi-filter")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeTableView(0)}}},[_c('v-icon',[_vm._v("mdi-playlist-check")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeTableView(1)}}},[_c('v-icon',[_vm._v("mdi-alarm-panel")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeTableView(2)}}},[_c('v-icon',[_vm._v("mdi-airplane")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeTableView(3)}}},[_c('v-icon',[_vm._v("mdi-cash-multiple")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeTableView(4)}}},[_c('v-icon',[_vm._v("mdi-shape-plus")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"mobile-breakpoint":"0","headers":_vm.computedHeaders,"items":_vm.computedVmList,"item-key":"ID","footer-props":{
      'items-per-page-options': [10, 20, 50, 100, -1],
      'items-per-page-text': 'Строк на странице',
      'items-per-page-all-text': 'Все',
      'page-text': '{0}-{1} из {2}'
    },"items-per-page":100,"loading":"true","loading-text":"Загрузка данных...","show-select":_vm.showselectors,"dense":""},scopedSlots:_vm._u([{key:"item.VMMODE",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getVmStatusColor(item.VMMODE,item.SessDate,item.AliveTmoM)}},[_vm._v(" "+_vm._s(_vm.getVmStatusPic(item.VMMODE))+" ")])]}},{key:"item.Alias",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.Alias))])]}},{key:"item.LAINC",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold green--text"},[_vm._v(_vm._s(_vm.toCur(item.LAINC)))])]}},{key:"item.LEINC",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.toCur(item.LEINC)))])]}},{key:"item.LFINC",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.toCur(item.LFINC)))])]}},{key:"item.LCINC",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.toCur(item.LCINC)))])]}},{key:"item.TDAYAINC",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCur(item.TDAYAINC)))])]}},{key:"item.YDAYAINC",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCur(item.YDAYAINC)))])]}},{key:"item.BYDAYAINC",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCur(item.BYDAYAINC)))])]}},{key:"item.CollLAINC",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCur(item.CollLAINC)))])]}},{key:"item.CollLCINC",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCur(item.CollLCINC)))])]}},{key:"item.CollLEINC",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCur(item.CollLEINC)))])]}},{key:"item.LastReceiptDate",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.LastReceiptDate),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.LastReceiptDate))+" ")])]}},{key:"item.LastCashVend",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.LastCashVend),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.LastCashVend))+" ")])]}},{key:"item.LastCashlessVend",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.LastCashlessVend),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.LastCashlessVend))+" ")])]}},{key:"item.SessDate",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.SessDate),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.SessDate))+" ")])]}},{key:"item.ServDate",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.ServDate),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.ServDate))+" ")])]}},{key:"item.LoadDate",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.LoadDate),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.LoadDate))+" ")])]}},{key:"item.CollDate",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.CollDate),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.CollDate))+" ")])]}},{key:"item.SETV",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold blue--text"},[_vm._v(_vm._s(item.SETV))])]}},{key:"item.SETI",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold blue--text"},[_vm._v(_vm._s(item.SETI))])]}}]),model:{value:(_vm.selecteditems),callback:function ($$v) {_vm.selecteditems=$$v},expression:"selecteditems"}},[_vm._v(" // https://www.codeply.com/p/kIlxX2jTZ1/vuetify-datatable-with-sum-footer "),(!_vm.showselectors)?_c('template',{slot:"body.append"},[_c('tr',_vm._l((_vm.computedHeaders),function(item,index){return _c('th',{key:index},[_c('div',{staticClass:"body-2 text-right font-weight-bold blue--text"},[_vm._v(" "+_vm._s(_vm.sumField(index))+" ")])])}),0)]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }