<template>
  <div class="test">
  <v-card
        class="d-flex align-start py-2"
        color="grey lighten-3"
        flat
        tile
      >
    <v-row
      justify="space-around"
      class="text-body-2"
      >
    <v-col>
      <strong>#Тест{{ netinfo }}</strong> {{ netinfo.ShortAddress }}
    </v-col>
    <v-col>
      {{ netinfo }}
    </v-col>
    <v-col>
      <v-icon small color="brown">mdi-message-minus</v-icon>Обновлено {{ getSmartDate(updated) }}
    </v-col>
  </v-row>
  </v-card>

  <v-card
        class="d-flex align-start"
        flat
        tile
      >
    <v-toolbar dense>
      <v-btn icon :outlined="showselectors" @click="changeSelMode()">
        <v-icon :color="getSelColor()">mdi-filter</v-icon>
      </v-btn>

      <v-btn icon @click="changeTableView(0)">
        <v-icon>mdi-playlist-check</v-icon>
      </v-btn>

      <v-btn icon @click="changeTableView(1)">
        <v-icon>mdi-alarm-panel</v-icon>
      </v-btn>

      <v-btn icon @click="changeTableView(2)">
        <v-icon>mdi-airplane</v-icon>
      </v-btn>

      <v-btn icon @click="changeTableView(3)">
        <v-icon>mdi-cash-multiple</v-icon>
      </v-btn>

      <v-btn icon @click="changeTableView(4)">
        <v-icon>mdi-shape-plus</v-icon>
      </v-btn>
    </v-toolbar>
  </v-card>


    <v-data-table mobile-breakpoint="0"
      :headers="computedHeaders"
      :items="computedVmList"
      v-model="selecteditems"
      item-key="ID"
      :footer-props="{
        'items-per-page-options': [10, 20, 50, 100, -1],
        'items-per-page-text': 'Строк на странице',
        'items-per-page-all-text': 'Все',
        'page-text': '{0}-{1} из {2}'
      }"
      :items-per-page="100"
      class="elevation-2"
      loading="true"
      loading-text="Загрузка данных..."
      :show-select="showselectors"
      dense>

      // https://www.codeply.com/p/kIlxX2jTZ1/vuetify-datatable-with-sum-footer
      <template v-slot:item.VMMODE="{ item }">
        <v-icon :color="getVmStatusColor(item.VMMODE,item.SessDate,item.AliveTmoM)">
          {{ getVmStatusPic(item.VMMODE) }}
        </v-icon>
      </template>
      <template v-slot:item.Alias="{ item }">
        <div class="font-weight-bold">{{ item.Alias }}</div>
      </template>
      <template v-slot:item.LAINC="{ item }">
        <div class="font-weight-bold green--text">{{ toCur(item.LAINC) }}</div>
      </template>
      <template v-slot:item.LEINC="{ item }">
        <div class="green--text">{{ toCur(item.LEINC) }}</div>
      </template>
      <template v-slot:item.LFINC="{ item }">
        <div class="green--text">{{ toCur(item.LFINC) }}</div>
      </template>
      <template v-slot:item.LCINC="{ item }">
        <div class="green--text">{{ toCur(item.LCINC) }}</div>
      </template>
      <template v-slot:item.TDAYAINC="{ item }">
        <div>{{ toCur(item.TDAYAINC) }}</div>
      </template>
      <template v-slot:item.YDAYAINC="{ item }">
        <div>{{ toCur(item.YDAYAINC) }}</div>
      </template>
      <template v-slot:item.BYDAYAINC="{ item }">
        <div>{{ toCur(item.BYDAYAINC) }}</div>
      </template>
      <template v-slot:item.CollLAINC="{ item }">
        <div>{{ toCur(item.CollLAINC) }}</div>
      </template>
      <template v-slot:item.CollLCINC="{ item }">
        <div>{{ toCur(item.CollLCINC) }}</div>
      </template>
      <template v-slot:item.CollLEINC="{ item }">
        <div>{{ toCur(item.CollLEINC) }}</div>
      </template>
      <template v-slot:item.LastReceiptDate="{ item }">
        <v-chip :color="getDeltaColor(item.LastReceiptDate)" dark small>
          {{ getDeltaDate(item.LastReceiptDate) }}
        </v-chip>
      </template>
      <template v-slot:item.LastCashVend="{ item }">
        <v-chip :color="getDeltaColor(item.LastCashVend)" dark small>
          {{ getDeltaDate(item.LastCashVend) }}
        </v-chip>
      </template>
      <template v-slot:item.LastCashlessVend="{ item }">
        <v-chip :color="getDeltaColor(item.LastCashlessVend)" dark small>
          {{ getDeltaDate(item.LastCashlessVend) }}
        </v-chip>
      </template>
      <template v-slot:item.SessDate="{ item }">
        <v-chip :color="getDeltaColor(item.SessDate)" dark small>
          {{ getDeltaDate(item.SessDate) }}
        </v-chip>
      </template>
      <template v-slot:item.ServDate="{ item }">
        <v-chip :color="getDeltaColor(item.ServDate)" dark small>
          {{ getDeltaDate(item.ServDate) }}
        </v-chip>
      </template>
      <template v-slot:item.LoadDate="{ item }">
        <v-chip :color="getDeltaColor(item.LoadDate)" dark small>
          {{ getDeltaDate(item.LoadDate) }}
        </v-chip>
      </template>
      <template v-slot:item.CollDate="{ item }">
        <v-chip :color="getDeltaColor(item.CollDate)" dark small>
          {{ getDeltaDate(item.CollDate) }}
        </v-chip>
      </template>
      <template v-slot:item.SETV="{ item }">
        <div class="font-weight-bold blue--text">{{ item.SETV }}</div>
      </template>
      <template v-slot:item.SETI="{ item }">
        <div class="font-weight-bold blue--text">{{ item.SETI }}</div>
      </template>
      <template v-if="!showselectors" slot="body.append">
        <tr>
            <th v-for="(item,index) in computedHeaders" v-bind:key="index">
              <div class="body-2 text-right font-weight-bold blue--text">
              {{ sumField(index) }}
            </div>
            </th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'Test',
  components: {
  },

  props: {
    id: { 
      type  : String,
      default: ''
    }
  },

  data: () => ({
        
        netinfo: '',
        timer: '',
        today: '',
        updated: '',
        selgroup: '',
        showselectors: false,
        selecteditems: [],
        selectedids: [],

        vmlist: [],
//        vmfilteredlist: [],

        defheaders: [
          { text: '', value: 'VMMODE', align: 'center', group: 0, sumable: 0 },
          { text: '№ авт', value: 'Alias', align: 'right', group: 0, sumable: 0 },
          { text: 'Имя', value: 'ShortAddress', align: 'left', group: 0, sumable: 0 },
          { text: 'Связь', value: 'SessDate', align: 'right', group: 0, sumable: 0 },
          { text: 'Чек', value: 'LastReceiptDate', align: 'right', group: 2, sumable: 0 },
          { text: 'Общие продажи', value: 'LACNT', align: 'right', group: 1, sumable: 1 },
          { text: 'Общая сумма', value: 'LAINC', align: 'right', group: 1, sumable: 2 },
          { text: 'б/нал продаж', value: 'LECNT', align: 'right', group: 1, sumable: 1 },
          { text: 'б/нал сумма', value: 'LEINC', align: 'right', group: 1, sumable: 2 },
          { text: 'б/нал2 продаж', value: 'LFCNT', align: 'right', group: 1, sumable: 1 },
          { text: 'б/нал2 сумма', value: 'LFINC', align: 'right', group: 1, sumable: 2 },
          { text: 'Посл.б/н оплата', value: 'LastCashlessVend', align: 'right', group: 2, sumable: 0 },
          { text: 'Нал продаж', value: 'LCCNT', align: 'right', group: 1, sumable: 1 },
          { text: 'Нал сумма', value: 'LCINC', align: 'right', group: 1, sumable: 2 },
          { text: 'Посл.нал оплата', value: 'LastCashVend', align: 'right', group: 2, sumable: 0 },
          { text: 'Общ.сумма сегодня', value: 'TDAYAINC', align: 'right', group: 2, sumable: 2 },
          { text: 'Общ.сумма вчера', value: 'YDAYAINC', align: 'right', group: 2, sumable: 2 },
          { text: 'Общ.сумма п/вчера', value: 'BYDAYAINC', align: 'right', group: 2, sumable: 2 },
          { text: 't товаров \u2103', value: 'SETV', align: 'right', group: 4, sumable: 0 },
          { text: 't системы \u2103', value: 'SETI', align: 'right', group: 4, sumable: 0 },
          { text: 'Счет игры', value: 'LGCNT', align: 'right', group: 4, sumable: 1 },
          { text: 'Счет призы', value: 'LZCNT', align: 'right', group: 4, sumable: 1 },
          { text: 'Инкас', value: 'CollDate', align: 'right', group: 3, sumable: 0 },
          { text: 'Инкас сумма', value: 'CollLAINC', align: 'right', group: 3, sumable: 2 },
          { text: 'Инкас нал', value: 'CollLCINC', align: 'right', group: 3, sumable: 2 },
          { text: 'Инкас б/нал', value: 'CollLEINC', align: 'right', group: 3, sumable: 2 },
          { text: 'Загруз', value: 'LoadDate', align: 'right', group: 3, sumable: 0 },
//          { text: 'Сервис', value: 'ServDate', align: 'right' },
//          { text: '', value: 'data-table-expand' },
        ],
      
      headers: [
        ],

  }),

  computed: {
   computedHeaders () {
      // return this.headers;
      // https://www.digitalocean.com/community/tutorials/js-array-search-methods-ru
      // console.log(vmlist.filter(eq => eq.value))
      // return this.defheaders.filter(el => this.vmlist.find(em => em.el.value != null)) != undefined ? true : false;
      return this.headers.filter(el => this.vmlist.find(em => em[el.value] != null));
   },

   computedVmList ()
    {
      if ((this.showselectors==true) || (this.selectedids.length == 0))
      {
        return this.vmlist;
      }
    var dataf = this.vmlist.filter(el => this.selectedids.includes(el.ID));
    return dataf;
    },

  },

	methods: {
    
    toCur(num)
    {
      return Intl.NumberFormat("ru").format(num);
    },

    getSelColor()
    {
      if (this.selectedids.length == 0)
        return "undefined";
      else
        return "red";
    },

    changeSelMode() 
    {
    if (this.showselectors)
    {
      this.selectedids = this.selecteditems.map(obj => obj.ID);
    }
    else
    {
      if (this.selectedids.length > 0)
      {
        // construct table selection from ids
        const existsids = this.selectedids.filter(item => this.vmlist.includes(vm => vm.ID == item));
        this.selecteditems = existsids.map(function(el) {
            return { "ID": el };
         });
      }
    }
    this.showselectors = this.showselectors ? false : true;
    localStorage.showselectors = this.showselectors;
    localStorage.selectedids = JSON.stringify(this.selectedids);
    },

    sumField(idx) 
    {
        // sum data in give key (property)
        if (this.computedHeaders[idx].sumable)
        {
          const val = this.computedVmList.reduce((a, b) => a + (b[this.computedHeaders[idx].value] || 0), 0);
          if (this.computedHeaders[idx].sumable == 2) // money style
            return this.toCur(val);
          return val;
        }
          else
            return null;
    },

    changeTableView (val)
    {
      this.selgroup = val;
      if (0 == this.selgroup)
        this.headers = this.defheaders;
      else
        this.headers = this.defheaders.filter(el => el.group == this.selgroup || el.group == 0);
    },
      
    getSmartDate (val)
    {
      
//console.log(val + '~' + this.today);//###
      if (val == '' || val == null)
        return '';
      if (this.today == '')
        return '';
      var date = val.getFullYear()+'-'+String((val.getMonth()+1)).padStart(2, "0")+'-'+ String(val.getDate()).padStart(2, "0");
      var time = val.getHours() + ":" + String(val.getMinutes()).padStart(2, "0") + ":" + String(val.getSeconds()).padStart(2, "0");
      if (this.today.getDate() == val.getDate() && this.today.getMonth() == val.getMonth() && this.today.getFullYear() == val.getFullYear())
        return time;
      else
        return date;        
    },

    getDeltaDate (val)
    {
//console.log(val + '~' + this.today);
      if (val == '' || val == null)
        return '?';
      if (this.today == '')
        return 'DateErr';
      val = new Date(val);
      var difs = parseInt((this.today - val) / 1000);
      if (difs < 60)
        return '<1m';
      var difm = parseInt(difs / 60);
      if (difm < 60)
        return difm + 'm';
      var difh = parseInt(difm / 60);
      difm = difm % 60;
      var difd = parseInt(difh / 24);
      difh = difh % 24;
      if (difd > 365)
        return '>1y';
      var dif = '';
      if (difd > 0)
        dif += String(difd) + 'd';
      if ((difh > 0 && difd == 0) || (difd > 0 && difd < 10))
        dif += String(difh) + 'h';
      if (0 == difd)
        dif += String(difm) + 'm';
      return dif;
    },

    getDeltaColor (val) {
      if (val == '' || val == null)
        return 'grey';
      if (this.today == '')
        return 'grey';
      val = new Date(val);
      var difs = (this.today - val) / 1000;

      if (difs <= 60 * 60) return 'green';  // 1 hour
      else if (difs <= 24 * 60 * 60) return 'orange'; // 1 day
      else return 'red';  // more than 1 day
    },

    getVmStatusColor (status, sessdate, alivetmo)
    {
      var val = new Date(sessdate);
      var difs = (this.today - val) / (1000 * 60);

      if (0 != alivetmo && difs >= alivetmo)
        return "grey";
      else if (0 == status)
        return "red";
      else
        return "green";
    },

    getVmStatusPic (status)
    {
      if ("0" == status)
        return "mdi-cancel";
      else
        return "mdi-check-circle";
    },

    getDataFromApi ()
    {
      this.today = new Date();
      const vm = this;
      if (this.id != '')
      {
        
        var req;

        req = 'https://q.emhit.ru/net/' + Number(this.id);
        this.axios.get(req, { withCredentials: false }).then(function(response) {
          vm.vmlist = response.data;
          //          vm.vmfilteredlist = vm.filterVmList(vm.vmlist);
//          console.log(response.data);
        }, function(error) {
          console.log(error.statusText);
        });

        this.updated = this.today;

      }
      else
      {
      this.vmlist = [ {'Alias':'Ошибка загрузки данных'} ];
      }
    }

	},
  

  mounted: function() {
    this.getDataFromApi();
    this.timer = setInterval(this.getDataFromApi, 1*60000);
    if (localStorage.selgroup)
      this.selgroup = localStorage.selgroup;
    else
      this.selgroup = 0;

    if (localStorage.selectedids)
    {
      this.selectedids = JSON.parse(localStorage.selectedids);
    }
    else
    {
      this.selectedids = [];
    }

    this.changeTableView(this.selgroup);
  },

  watch: {
    selgroup(newSelgroup) {
      localStorage.selgroup = newSelgroup;
    },
  }

}
</script>
